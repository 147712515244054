import mixpanel from 'mixpanel-browser';
import { isInternalUser } from './url-helpers';
import * as Sentry from '@sentry/browser';

const isMixpanelInitialized = () => {
  return mixpanel && typeof mixpanel.track === 'function' && process.env.REACT_APP_MIXPANEL_TOKEN && !mixpanel.__loaded;
};

const mixpanelHelper = {
  identifyUser: (data) => {
    const {
      id,
      email,
      company,
      language,
      courses = [],
      typeOfJob,
      livePracticeCount = 0,
      numberOfCoaches = 0,
      ceregoProgress = 0,
      numberOfMsgsToCoach = 0,
      numberOfMsgsToLH = 0,
      saasType,
      startDate,
      learningReason
    } = data;

    if (!mixpanel) return;
    if (!id || !email) return;
    if (!isMixpanelInitialized()) return;
    try {
      const systemLanguage = navigator.language || undefined; // Retrieves system language

      mixpanel.identify(id);

      let courseName = undefined;
      let courseLevel = undefined;
      let courseLanguage = undefined;

      if (courses.length > 0) {
        courseName = courses[0];
        const levelMatch = courseName.match(/\b(A1|A2|B1|B2|C1|C2)\b/i);
        if (levelMatch) {
          courseLevel = levelMatch[0];
          courseLanguage = courseName
            .replace(courseLevel, '')
            .replace(/\d+\.\d+/, '')
            .trim(); // Remove level and "2.0"
        } else {
          courseLanguage = courseName.replace(/\d+\.\d+/, '').trim(); // Just remove "2.0" if no level found
        }
      }

      mixpanel.people.set({
        id,
        email,
        company: company && company[0]?.name,
        language,
        courseLevel: courseLevel && courseLevel[0],
        courseName,
        courseLanguage,
        typeOfJob,
        livePracticeCount,
        numberOfCoaches,
        ceregoProgress,
        numberOfMsgsToCoach,
        numberOfMsgsToLH,
        systemLanguage,
        saasType,
        startDate,
        learningReason
      });
      if (isInternalUser(email)) mixpanel.opt_out_tracking();
    } catch (err) {
      console.warn('Analytics error:', err);
      Sentry.captureException(err);
    }
  },
  visitedPage: (pageName, props) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Visited ${pageName} page`, props);
  },
  openedModal: (modalName, props) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Opened ${modalName} modal`, props);
  },
  closedModal: (modalName, props) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Closed ${modalName} modal`, props);
  },
  showedSnackbar: (snackbarName, props) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Showed ${snackbarName} snackbar`, props);
  },
  closedSnackbar: (snackbarName, props) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Closed ${snackbarName} snackbar`, props);
  },
  recordElapsedTime: (component, timeSpent) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Record time spent on ${component}`, { timeSpent });
  },
  lessonAction: (action, { ...rest }) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`${action} lesson(s)`, { ...rest });
  },
  postError: (errorMessage) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Error: ${errorMessage}`);
  },
  click: (buttonName) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(`Clicked ${buttonName}`);
  },
  identify: (id) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.identify(id);
  },
  alias: (id) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.alias(id);
  },
  track: (name, props) => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.track(name, props);
  },
  opt_out_tracking: () => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.opt_out_tracking();
  },
  opt_in_tracking: () => {
    if (!mixpanel || !isMixpanelInitialized()) return;
    mixpanel.opt_in_tracking();
  },
  people: {
    set: (props) => {
      if (!mixpanel || !isMixpanelInitialized()) return;
      mixpanel.people.set(props);
    },
    track_charge: (val) => {
      if (!mixpanel || !isMixpanelInitialized()) return;
      mixpanel.people.track_charge(val);
    }
  }
};

export default mixpanelHelper;
