import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LessonResource from '../../../resources/LessonResource';
import LessonCeregoResource from '../../../resources/LessonCeregoResource';
import { capitalize } from '../../../../utils/string-helpers';
import Button from '../../../design-system/button/Button';
import { setCurrentCeregoAssignment } from '../../../../actions/assignments';
import TextLoader from '../../../design-system/loading/text/TextLoader';

import styles from './CurrentUnit.module.scss';

import { ReactComponent as IconCheck } from '../../../../assets/icons/icon_check.svg';
import { ReactComponent as IconLinkTo } from '../../../../assets/icons/icon_link-to.svg';
import { RESOURCE_TYPE } from '../../../resources/Enum';
import { BOOKING_URL } from '../../../new-booking/Enum';

const CurrentUnit = ({
  alreadyJoinedCourses,
  history,
  introClass,
  isLoadingProgress,
  isLoadingUnit,
  mustTakeIntroClass,
  setCurrentCeregoAssignmentAction,
  selectedUnit,
  status,
  student
}) => {
  const [ceregoResources, setCeregoResources] = useState([]);
  const [otherResources, setOtherResources] = useState([]);
  const [lessonSummary, setLessonSummary] = useState(null);

  const handleAddResources = () => {
    if (!selectedUnit?.resources) return;
    const { ceregoResourcesArray, otherResourcesArray } = selectedUnit.resources
      .sort((a, b) => a.order_position - b.order_position)
      .filter((resource) => {
        const isLessonSummary = resource?.name === 'Lesson Summary' && resource?.user_type === 'student';
        if (isLessonSummary) setLessonSummary(resource);
        return !isLessonSummary;
      })
      .reduce(
        (acc, resource) => {
          if (resource.user_type !== student.user_type) return acc;
          if (resource?.type === RESOURCE_TYPE.CEREGO) return { ...acc, ceregoResourcesArray: [...acc.ceregoResourcesArray, resource] };
          return { ...acc, otherResourcesArray: [...acc.otherResourcesArray, resource] };
        },
        { ceregoResourcesArray: [], otherResourcesArray: [] }
      );
    setCeregoResources(ceregoResourcesArray);
    setOtherResources(otherResourcesArray);
  };

  useEffect(() => {
    handleAddResources();
  }, [selectedUnit]);

  const getCeregoUrl = (resource, alreadyJoinedCourses) => {
    let urlToAttach = resource.url;
    if (alreadyJoinedCourses && alreadyJoinedCourses.length > 0) {
      alreadyJoinedCourses.forEach((joinedCourse) => {
        if (joinedCourse.course.attributes && urlToAttach.includes(joinedCourse.course.attributes.slug)) {
          urlToAttach = `https://cerego.com/learn/courses/${joinedCourse.course.id}`;
          return urlToAttach;
        }
      });
    }
    return urlToAttach;
  };

  const handleOpenCeregoResource = (resource) => {
    setCurrentCeregoAssignmentAction(resource, () => {
      history.push('/assignment');
    });
  };

  const renderUnitStatus = () => {
    if (selectedUnit.booking_state === 'booked')
      return (
        <>
          <span>Booked</span>
          <IconCheck className={styles['icon-check']} />
        </>
      );

    if (selectedUnit.booking_state === 'happened')
      return (
        <>
          <span>Completed</span>
          <IconCheck className={styles['icon-check']} />
        </>
      );

    if (
      selectedUnit.booking_state === 'not-booked' &&
      ((mustTakeIntroClass && introClass?.status === 'happened') || !mustTakeIntroClass) &&
      !student.is_banco_unico_special_rules
    )
      return (
        <>
          <span className={styles.notBooked}>Your Live Practice isn&apos;t booked.</span>
          <Button outfit="link" onClick={() => history.push(BOOKING_URL)}>
            Book now
          </Button>
        </>
      );

    return <span className={styles.notBooked}>Your Live Practice isn&apos;t booked.</span>;
  };

  if (isLoadingUnit)
    return (
      <div className={styles.unitWrapper} id="unitWrapper">
        <div className={styles.loadingWrapper}>
          <TextLoader rows={8} rowsColor="gray" />
        </div>
      </div>
    );

  if (!Object.keys(selectedUnit).length) return;

  return (
    <div className={styles.unitWrapper} id="unitWrapper" data-testid="current-unit">
      <div className={styles.unitContent}>
        <div className={styles.titleWrapper}>
          {selectedUnit ? (
            <h2>
              Lesson <span id="dont-translate">{selectedUnit.number}</span>
            </h2>
          ) : undefined}
          <h1 id="dont-translate" className={[styles.title, status === 'future' ? styles.futureContent : undefined].join(' ')}>
            {selectedUnit?.name}
          </h1>
        </div>
        <div className={styles.contentWrapper}>
          {status === 'future' && (
            <LessonResource
              name="To access this lesson, please book a session with your coach."
              type="book"
              url="/?fromStudyArea=true"
              status={status}
              openCeregoResource={handleOpenCeregoResource}
              history={history}
              resource={null}
              lessonNumber={selectedUnit.number}
            />
          )}
          <div className={styles['unit-specific-content']}>
            <p id="dont-translate" className={[styles.contentDescription, status === 'future' ? styles.futureContent : undefined].join(' ')}>
              {selectedUnit?.description}
            </p>
            <div className={styles['unit-status']}>
              <p className={styles['live-class']}>{capitalize('session')}:</p>
              {renderUnitStatus()}
            </div>
            {Boolean(ceregoResources.length) && (
              <div className={styles.assignments}>
                <div className={styles.header}>
                  <h3 className={styles.title}>Assignments</h3>
                  <a className={styles['lesson-summary']} href={lessonSummary?.url} target="_blank" rel="no_ref noreferrer">
                    Lesson summary
                    <IconLinkTo />
                  </a>
                </div>
                <div className={styles.resources}>
                  {ceregoResources.map((resource, index) => (
                    <LessonCeregoResource
                      name={resource?.name}
                      type={resource.type}
                      url={resource.type !== 'cerego' ? resource.url : getCeregoUrl(resource, alreadyJoinedCourses)}
                      userType={resource.user_type}
                      progress={(resource?.type === 'cerego' && resource?.progress) || 0}
                      status={status}
                      userIsBeta={student.is_beta_tester}
                      isCeregoResource={resource.type === 'cerego'}
                      openCeregoResource={handleOpenCeregoResource}
                      history={history}
                      resource={resource}
                      lessonNumber={selectedUnit.number}
                      resourceIndex={index + 1}
                      isLoadingProgress={isLoadingProgress}
                      key={resource?.name}
                      testId="cerego-resource"
                    />
                  ))}
                </div>
              </div>
            )}
            {Boolean(otherResources.length) && (
              <div className={styles.support}>
                <div className={styles.header}>
                  <h3 className={styles.title}>Supporting Content</h3>
                </div>
                <div className={styles.resources}>
                  {otherResources.map((resource, index) => (
                    <LessonResource
                      name={resource?.name}
                      type={resource.type}
                      url={resource.url}
                      userType={resource.user_type}
                      status={status}
                      userIsBeta={student.is_beta_tester}
                      history={history}
                      resource={resource}
                      lessonNumber={selectedUnit.number}
                      resourceIndex={index + 1}
                      key={resource?.name}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div id="fyfk-widget" />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    student: state.user,
    introClass: state.sessions.introSession.introClass,
    mustTakeIntroClass: state.student.mustTakeIntroClass
  };
}

CurrentUnit.propTypes = {
  selectedUnit: PropTypes.object,
  status: PropTypes.string,
  alreadyJoinedCourses: PropTypes.array,
  history: PropTypes.object.isRequired,
  mustTakeIntroClass: PropTypes.bool.isRequired,
  introClass: PropTypes.object.isRequired,
  setCurrentCeregoAssignmentAction: PropTypes.func.isRequired,
  student: PropTypes.object.isRequired,
  isLoadingProgress: PropTypes.bool,
  isLoadingUnit: PropTypes.bool
};

CurrentUnit.defaultProps = {
  alreadyJoinedCourses: [],
  isLoadingProgress: false,
  isLoadingUnit: false,
  selectedUnit: undefined
};

export default connect(mapStateToProps, {
  setCurrentCeregoAssignmentAction: setCurrentCeregoAssignment
})(CurrentUnit);
