import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import styles from './AutomaticBookingsModal.module.scss';

const AutomaticBookingsModal = ({ isOpen, onClose, onAccept, onDecline }) => {
  const isFeatureEnabled = useFeatureIsOn('automatic-bookings');

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className={styles.modal} overlayClassName={styles.overlay}>
      <div className={styles.content}>
        <h2>Introducing Automatic Session Booking</h2>
        <p>
          Your new Chatterbox learning experience starts now!
          <br />
          <br />
          To ensure you achieve your goals, we&apos;ll automatically book your next session for the same day and time after each completed session.
          You&apos;ll always be notified and can easily change or cancel.
        </p>
        <div className={styles.buttons}>
          <button className={styles.acceptButton} onClick={onAccept}>
            Sounds good
          </button>
          <button className={styles.declineButton} onClick={onDecline}>
            No thanks, I&apos;ll book manually
          </button>
        </div>
      </div>
    </Modal>
  );
};

AutomaticBookingsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired
};

export default AutomaticBookingsModal;
