import React, { useState, useEffect, useRef } from 'react';
import { Widget } from '@typeform/embed-react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Mixpanel from '../../../utils/mixpanel-helper.js';
import styles from './Test.module.scss';
import useInitRecording from '../../../hooks/useInitRecording';
import LoadingSpinner from '../../design-system/loading/spinner/LoadingSpinner.js';
import { retrievePlacementTestResults } from '../../../actions/new-onboarding.js';

const TYPEFORM_CODES = Object.freeze({
  arabic: 'HiHqRGrv',
  french: 'FSDb7ftx',
  english: 'w1ZhefOq',
  spanish: 'gAR7EfIR',
  dutch: 'TJPq4hfr',
  german: 'i7S3G7uL',
  néerlandais: 'F8KLVEeN'
});

const Test = ({ placementTestInfo, questionId, retrievePlacementTestResultsAction, user, waitingListUrl }) => {
  const [typeformCode, setTypeformCode] = useState('');
  const [isTypeformReady, setIsTypeformReady] = useState(false);
  const [typeformOptions, setTypeformOptions] = useState({});
  const testRef = useRef(null);

  useEffect(() => {
    Mixpanel.visitedPage('Onboarding Step Placement Test');

    return () => endRecording();
  }, []);

  useEffect(() => {
    if (isTypeformReady) {
      testRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isTypeformReady]);

  const getTypeformCode = (language) => {
    if (!language) return;
    const code = TYPEFORM_CODES[language.toLowerCase()] || TYPEFORM_CODES['french'];
    setTypeformCode(code);
    return code;
  };

  const initializeTypeform = (typeformCode) => {
    if (!typeformCode) return;
    setTypeformOptions({
      hideHeaders: true,
      hideFooter: true,
      opacity: 100,
      autoClose: 0,
      onSubmit: (response) => formSubmited(response, typeformCode),
      hidden: {
        student_name: `${user.first_name} ${user.last_name}`,
        student_email: `${user.email}`
      },
      onQuestionChanged
    });
    setIsTypeformReady(true);
  };

  useEffect(() => {
    const { language } = placementTestInfo;
    if (!language) return;

    const typeformCode = getTypeformCode(language);
    initializeTypeform(typeformCode);
  }, [placementTestInfo]);

  const [endRecording] = useInitRecording((endTime) => Mixpanel.recordElapsedTime('Onboarding Step Placement Test page', endTime));

  const formSubmited = (response, typeformCode) => {
    retrievePlacementTestResultsAction({
      placement_test_id: typeformCode,
      response_id: response.responseId,
      token: user.token
    });
  };

  const onQuestionChanged = (data) => {
    if (data && questionId && data.ref === questionId) {
      window.location.href = waitingListUrl;
    }
  };

  return (
    <div className={styles['test-wrapper']}>
      {isTypeformReady ? (
        <div className={styles['test']} ref={testRef}>
          <Widget id={typeformCode} className={styles.typeformContainer} {...typeformOptions} />
        </div>
      ) : (
        <div className={styles.placementTestLoadingContainer}>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  placementTestInfo: state.user.placement_test_info,
  user: state.user
});

Test.propTypes = {
  placementTestInfo: PropTypes.object,
  user: PropTypes.object.isRequired,
  nextFunction: PropTypes.func.isRequired,
  retrievePlacementTestResultsAction: PropTypes.func.isRequired,
  setCourse: PropTypes.func.isRequired,
  typeformCode: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  waitingListUrl: PropTypes.string
};

Test.defaultProps = {
  waitingListUrl: 'https://chatterbox.io/waitinglist'
};

export default connect(mapStateToProps, {
  retrievePlacementTestResultsAction: retrievePlacementTestResults
})(Test);
