import React from 'react';
import PropTypes from 'prop-types';
import styles from './AutomaticBookingsToggle.module.scss';

const AutomaticBookingsToggle = ({ isEnabled, onChange }) => {
  return (
    <>
      <div className={styles.toggleContainer}>
        <span className={styles.toggleLabel}>Automatic booking</span>
        <label className={styles.switch}>
          <input type="checkbox" checked={isEnabled} onChange={() => onChange(!isEnabled)} aria-label="Toggle automatic bookings" />
          <span className={styles.slider}></span>
        </label>
      </div>
      <p>If turned on, we&apos;ll automatically book your next session. You&apos;ll always be notified and can easily change or cancel.</p>
    </>
  );
};

AutomaticBookingsToggle.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default AutomaticBookingsToggle;
