import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/browser';

import { validate, required, email } from './validate.js';
import { resetLoginError } from '../../actions/user';
import { clearLogInError, postLogIn, postLogInImpersonate, redirectAfterLogin } from '../../actions/new-login.js';
import { renderField } from '../../utils/form-helpers';
import Button from '../design-system/button/Button';

import formStyles from '../../style/utils/_form.module.scss';
import styles from '../../style/components/account/LoginForm.module.scss';
import mixpanel from '../../utils/mixpanel-helper.js';
import ErrorMessage from '../design-system/helper-messages/error-message/ErrorMessage.jsx';

const LoginForm = ({
  clearLogInErrorAction,
  handleSubmit,
  hasLoginError,
  isLoadingLogin,
  loginErrorMsg,
  postLogInAction,
  postLogInImpersonateAction,
  setIsSSO
}) => {
  const { search } = useLocation();
  const impersonateToken = new URLSearchParams(search).get('impersonate_token');
  const history = useHistory();
  const to = history?.location?.state?.to;

  useEffect(() => {
    try {
      mixpanel.visitedPage('Login');
    } catch (err) {
      console.warn('Analytics error:', err);
      Sentry.captureException(err);
    }
  }, []);

  const onSubmit = (values) => {
    if (impersonateToken) {
      postLogInImpersonateAction({
        token: impersonateToken,
        successCallback: (user) => redirectAfterLogin(user, history, to)
      });
    } else {
      postLogInAction({
        data: values,
        successCallback: (user) => redirectAfterLogin(user, history, to)
      });
    }
  };

  const resetError = () => {
    if (hasLoginError) {
      clearLogInErrorAction();
    }
  };

  return (
    <form className={formStyles.form} onSubmit={handleSubmit(onSubmit)} onChange={() => resetError()} data-testid="login-form">
      {!impersonateToken && (
        <>
          <Field label="Your email" name="email" type="email" component={renderField} validate={[email, required]} />
          <Field label="Your password" name="password" type="password" component={renderField} validate={[required]} />
        </>
      )}
      <ErrorMessage errorMsg={loginErrorMsg} onClose={resetError} show={hasLoginError} />
      <div className={styles.submitContainer}>
        <Button type="submit" outfit="greenButton" loading={isLoadingLogin}>
          Log in
        </Button>
        <div className={styles.spliter}>
          <p className={styles.or}>or</p>
        </div>
        <div className={styles.otherProviders}>
          <Button type="button" outfit="transparentButton" onClick={() => setIsSSO(true)}>
            Log in with your company credentials
          </Button>
        </div>
        <div className={styles.passwordReset}>
          <Link to="/reset/">Reset password</Link>
        </div>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  clearLogInErrorAction: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hasLoginError: PropTypes.bool.isRequired,
  isLoadingLogin: PropTypes.bool.isRequired,
  loginErrorMsg: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  resetLoginErrorAction: PropTypes.func.isRequired,
  postLogInAction: PropTypes.func.isRequired,
  postLogInImpersonateAction: PropTypes.func.isRequired,
  setIsSSO: PropTypes.func.isRequired
};

LoginForm.defaultProps = {
  isLoadingLogin: false,
  hasLoginError: false,
  loginErrorMsg: ''
};

const mapStateToProps = (state) => ({
  isLoadingLogin: state.newLogin.logIn.isLoadingPostLogIn,
  hasLoginError: state.newLogin.logIn.hasPostLogInError,
  loginErrorMsg: state.newLogin.logIn.postLogInErrorMessage
});

export default reduxForm({
  validate,
  form: 'LoginForm'
})(
  connect(mapStateToProps, {
    clearLogInErrorAction: clearLogInError,
    resetLoginErrorAction: resetLoginError,
    postLogInAction: postLogIn,
    postLogInImpersonateAction: postLogInImpersonate
  })(LoginForm)
);
