export const RESET_LOGIN_ERROR = 'LOGIN/RESET_ERROR';
export const USER_LOGOUT = 'LOGIN/USER_LOGOUT';

export const GET_USER_SUCCESS = 'USER/GET_USER_SUCCESS';

export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const GET_TUTOR_PROFILE = 'TUTOR_PROFILE/GET';
export const GET_TUTOR_PROFILE_ERROR = 'TUTOR_PROFILE/GET_ERROR';

export const ACCOUNT_UPDATE_SUCCESS = 'ACCOUNT/UPDATE_SUCCESS';
export const ACCOUNT_UPDATE_FAILED = 'ACCOUNT/UPDATE_FAILED';
export const REQUEST_ACCOUNT_UPDATE = 'ACCOUNT/REQUEST_ACCOUNT_UPDATE';
export const RESET_ACCOUNT_UPDATE = 'ACCOUNT/RESET_ACCOUNT_UPDATE';
export const REQUEST_UPDATE_PASSWORD = 'ACCOUNT/REQUEST_UPDATE_PASSWORD';
export const PASSWORD_UPDATE_SUCCESS = 'PASSWORD/UPDATE_SUCCESS';
export const PASSWORD_UPDATE_FAILED = 'PASSWORD/UPDATE_FAILED';
export const RESET_UPDATE_PASSWORD = 'PASSWORD/RESET_UPDATE_PASSWORD';

export const CREATE_ABSENCE_PERIOD = 'ABSENCE_PERIOD/CREATE_SUCCESS';
export const DELETE_ABSENCE_PERIOD = 'ABSENCE_PERIOD/DELETE_SUCCESS';
export const GET_ABSENCE_PERIODS = 'ABSENCE_PERIOD/GET_SUCCESS';
export const GET_ABSENCE_PERIODS_ERROR = 'ABSENCE_PERIOD/GET_ERROR';
export const UPDATE_ABSENCE_PERIOD = 'ABSENCE_PERIOD/UPDATE_SUCCESS';

export const GET_ALL_AVAILABILTIES = 'GET_ALL_AVAILABILTIES';

export const GET_AVAILABILTIES = 'AVAILABILITY/GET_SUCCESS';
export const NEW_AVAILABILITY = 'AVAILABILITY/CREATE_SUCCESS';
export const DELETE_AVAILABILITY = 'AVAILABILITY/DELETE_SUCCESS';
export const UPDATE_AVAILABILITY = 'AVAILABILITY/UPDATE_SUCCESS';

export const CANCEL_APPOINTMENT = 'APPOINTMENT/CANCEL_SUCCESS';
export const CANCEL_APPOINTMENT_ERROR = 'APPOINTMENT/CANCEL_ERROR';
export const CREATE_APPOINTMENT = 'APPOINTMENT/CREATE_SUCCESS';
export const GET_APPOINTMENTS = 'APPOINTMENT/GET_SUCCESS';
export const LOAD_MORE_APPOINTMENTS = 'APPOINTMENT/LOAD_MORE_APPOINTMENTS';
export const GET_APPOINTMENTS_ERROR = 'APPOINTMENT/GET_ERROR';
export const GET_LAST_APPOINTMENT = 'LAST_APPOINTMENT/GET';
export const GET_LAST_APPOINTMENT_ERROR = 'LAST_APPOINTMENT/GET_ERROR';
export const GET_APPOINTMENTS_COUNT = 'APPOINTMENT/GET_APPOINTMENTS_COUNT';
export const GET_APPOINTMENTS_COUNT_ERROR = 'APPOINTMENT/GET_APPOINTMENTS_COUNT_ERROR';
export const RESET_APPOINTMENTS = 'RESET_APPOINTMENTS';
export const GET_APPOINTMENT_TO_RATE_ERROR = 'GET_APPOINTMENT_TO_RATE_ERROR';
export const GET_APPOINTMENT_TO_RATE = 'GET_APPOINTMENT_TO_RATE';

export const GET_PRODUCT_TYPES = 'PRODUCT_TYPES/GET_SUCCESS';
export const GET_PRODUCT_TYPES_ERROR = 'PRODUCT_TYPES/GET_ERROR';

export const GET_ORDER_INFO = 'ORDER_INFO/GET_SUCCESS';
export const GET_ORDER_INFO_ERROR = 'ORDER_INFO/GET_ERROR';

export const CREATE_INVOICE = 'INVOICE/CREATE_SUCCESS';
export const GET_INVOICES = 'INVOICES/GET_SUCCESS';
export const GET_INVOICES_ERROR = 'INVOICES/GET_ERROR';

export const GET_LANGUAGES = 'LANGUAGES/GET_SUCCESS';
export const GET_LANGUAGES_ERROR = 'LANGUAGES/GET_ERROR';
export const CACHED_LANGUAGES = 'LANGUAGES/CACHED_LANGUAGES ';

export const SET_APPOINTMENT_TYPE = 'APPOINTMENT_TYPE/SET';
export const SET_PRODUCT_TYPE = 'PRODUCT_TYPE/SET';
export const SET_LANGUAGE = 'LANGUAGE/SET';

export const GET_LESSON = 'LESSON/SUCCESS';
export const GET_LESSON_ERROR = 'LESSON/ERROR';
export const LOADING_LESSONS_STATUS = 'LESSONS/LOADING_STATUS';
export const CLEAN_LESSONS_ERROR_STATE = 'LESSONS/CLEAN_ERROR_STATE';
export const GET_COURSE_DETAILS = 'COURSE_DETAILS/SUCCESS';
export const GET_COURSE_DETAILS_ERROR = 'COURSE_DETAILS/ERROR';
export const GET_COURSE_PROGRESS = 'GET_COURSE_PROGRESS/SUCCESS';
export const GET_COURSE_ASSIGNMENT_PROGRESS = 'GET_COURSE_ASSIGNMENT_PROGRESS/SUCCESS';
export const GET_LESSON_STATUS = 'LESSON_STATUS/SUCCESS';
export const GET_LESSON_STATUS_ERROR = 'LESSON_STATUS/ERROR';

export const GET_LOCATIONS = 'LOCATIONS/SUCCESS';
export const GET_LOCATIONS_ERROR = 'LOCATIONS/ERROR';

export const GET_MEETING_PLACES = 'MEETING_PLACES/SUCCESS';
export const GET_MEETING_PLACES_ERROR = 'MEETING_PLACES/ERROR';

export const SEND_CHAT_CODE = 'CHAT_CODE/SUCCESS';

export const GET_GROUP_SESSIONS = 'GROUP_SESSIONS/SUCCESS';
export const GET_GROUP_SESSIONS_ERROR = 'GROUP_SESSIONS/ERROR';

export const SET_ONBOARDING_CHOICES = 'SET_ONBOARDING_CHOICES';
export const SET_TUTOR_ONBOARDING_CHOICES = 'SET_TUTOR_ONBOARDING_CHOICES';

export const UPDATE_TUTOR_INFO = 'UPDATE_TUTOR_INFO';

export const USER_HIDE_ONBOARDING = 'USER_HIDE_ONBOARDING';
export const USER_HIDE_CEREGO_POPUP = 'USER_HIDE_CEREGO_POPUP';
export const USER_HIDE_TUITION_BANNER = 'USER_HIDE_TUITION_BANNER';

export const SELECT_ASSIGNMENT = 'SELECT_ASSIGNMENT';
export const HIDE_STUDY_AREA_ONBOARDING = 'HIDE_STUDY_AREA_ONBOARDING';

export const GET_CLASSROOM_INFO = 'GET_CLASSROOM_INFO';
export const SET_USER_BOOKED = 'SET_USER_BOOKED';
export const SET_BOOKING_FREQUENCY = 'SET_BOOKING_FREQUENCY';

export const SET_ONBOARDING_STEP = 'SET_ONBOARDING_STEP';
export const INITIALIZE_ONBOARDING_DATA = 'INITIALIZE_ONBOARDING_DATA';
export const STORE_ONBOARDING_SELECTION = 'STORE_ONBOARDING_SELECTION';
export const RESET_ONBOARDING = 'RESET_ONBOARDING';
export const RESET_ONBOARDING_STEP = 'RESET_ONBOARDING_STEP';
export const SET_ONBOARDING_SUBMITTED = 'SET_ONBOARDING_SUBMITTED';
export const SET_PREFERRED_LANGUAGE = 'SET_PREFERRED_LANGUAGE';
export const OVERWRITE_USER_DATA = 'OVERWRITE_USER_DATA';
export const GRADUATION_UPDATE_SUCCESS = 'GRADUATION_UPDATE_SUCCESS';
export const SET_UG_WALKTHROUGH_SEEN = 'SET_UG_WALKTHROUGH_SEEN';

export const RATE_APPOINTMENT = 'RATE_APPOINTMENT';

export const GET_BOOKINGS_ERROR = 'NEW_BOOKING/GET_BOOKINGS_ERROR';
export const ADD_TO_RESCHEDULING_OR_CANCELING_LIST = 'NEW_BOOKING/ADD_TO_RESCHEDULING_OR_CANCELING_LIST';
export const CLEAN_PREVIOUS_STATE = 'NEW_BOOKING/CLEAN_PREVIOUS_STATE';
export const CLEAN_ERROR_STATE = 'NEW_BOOKING/CLEAN_ERROR_STATE';

export const NEXT_PAGE = 'TEST_PAGE/NEXT_PAGE';
export const PREVIOUS_PAGE = 'TEST_PAGE/PREVIOUS_PAGE';
export const RESET_PAGE = 'TEST_PAGE/RESET_PAGE';
export const SET_PAGE = 'TEST_PAGE/SET_PAGE';

export const SEND_EVENT_SUCCESS = 'EVENT/SEND_SUCCESS';
export const SEND_EVENT_FAIL = 'EVENT/SEND_FAIL';

export const CREATE_FORM = 'FORMS/CREATE_FORM';
export const CREATE_FIELD = 'FORMS/CREATE_FIELD';
export const CHANGE_FIELD = 'FORMS/CHANGE_FIELD';
export const RESET_FORM = 'FORMS/RESET_FORM';

export const TUTOR_STUDENTS_REQUEST = 'TUTOR_STUDENTS_REQUEST';
export const TUTOR_STUDENTS_SUCCESS = 'TUTOR_STUDENTS_SUCCESS';
export const TUTOR_STUDENTS_FAILURE = 'TUTOR_STUDENTS_FAILURE';
export const RESET_TUTOR_STUDENTS = 'RESET_TUTOR_STUDENTS';

export const SET_AUTOMATIC_BOOKINGS = 'SET_AUTOMATIC_BOOKINGS';
export const SET_AUTOMATIC_BOOKINGS_ASKED = 'SET_AUTOMATIC_BOOKINGS_ASKED';
