import { LoginActionTypes } from '../actions/new-login';
import {
  ACCOUNT_UPDATE_SUCCESS,
  GET_USER_SUCCESS,
  USER_LOGOUT,
  USER_HIDE_ONBOARDING,
  USER_HIDE_CEREGO_POPUP,
  HIDE_STUDY_AREA_ONBOARDING,
  SET_USER_BOOKED,
  USER_HIDE_TUITION_BANNER,
  SET_ONBOARDING_SUBMITTED,
  SET_PREFERRED_LANGUAGE,
  OVERWRITE_USER_DATA,
  SET_UG_WALKTHROUGH_SEEN,
  RESET_LOGIN_ERROR,
  ACCOUNT_UPDATE_FAILED,
  REQUEST_ACCOUNT_UPDATE,
  PASSWORD_UPDATE_SUCCESS,
  REQUEST_UPDATE_PASSWORD,
  PASSWORD_UPDATE_FAILED,
  RESET_UPDATE_PASSWORD,
  RESET_ACCOUNT_UPDATE,
  SET_AUTOMATIC_BOOKINGS,
  SET_AUTOMATIC_BOOKINGS_ASKED
} from '../actions/types';
import { UserActionTypes } from '../actions/user';
import { UserLicenceTypes } from '../domains/user/user-licence-types';

const initialState = {
  courses: [],
  email: '',
  first_name: '',
  id: null,
  last_name: '',
  location_name: '',
  phone_number: '',
  profile_picture: '',
  skype_id: '',
  student_id: '',
  zoom_id: null,
  webex_link: null,
  teams_email: null,
  teams_url: null,
  time_zone_name: '',
  user_type: '',
  is_beta_tester: false,
  is_test_user: false,
  is_staff: false,
  already_booked: false,
  chats_to_join: [],
  show_onboarding: false,
  show_cerego_popup: false,
  organization: [],
  booking_confirmation_and_cancelattion: false,
  booking_reminders: false,
  credit_top_up_confirmation: false,
  new_events_promotions_email: false,
  contact_for_feedback: false,
  booking_mobile: false,
  booking_reminders_mobile: false,
  created_datetime: new Date().toISOString(),
  visited_study_area: false,
  meetme_details: null,
  show_first_tuition_banner: false,
  from_mailchimp_activation: false,
  preferred_class: 'zoom',
  preferred_language: 'en',
  twilio_identity: 0,
  placement_test_info: {},
  needs_placement_test: false,
  graduation_date: new Date().toISOString(),
  needs_graduation_review: false,
  is_banco_unico_special_rules: false,
  ab_test_cohorts: [],
  is_individual_user: false,
  licence_type: UserLicenceTypes.legacy,
  license_expiration_date: new Date().toISOString(),
  final_exam_due_date: new Date().toISOString(),
  show_ug_walkthrough: false,
  date_joined: new Date().toISOString(),
  token: '',
  isLoadingLogin: false,
  isLoadingUpdate: false,
  isAccountUpdateSuccess: false,
  isLoadingPassword: false,
  isPasswordChangeSuccess: false,
  error: false,
  hasErrorUpdateAccount: false,
  hasErrorUpdatePassword: false,
  errorMsg: '',
  automatic_bookings: false,
  automatic_bookings_asked: false
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case HIDE_STUDY_AREA_ONBOARDING:
      return { ...state, ...action.payload };
    case GET_USER_SUCCESS:
      return { ...state, ...action.payload, isLoadingLogin: false };
    case RESET_LOGIN_ERROR:
      return { ...state, error: false, errorMsg: '' };
    case USER_LOGOUT:
      return {};
    case LoginActionTypes.RESET_SIGN_IN:
      return initialState;
    case SET_ONBOARDING_SUBMITTED:
      return {
        ...state,
        show_onboarding: false
      };
    case SET_PREFERRED_LANGUAGE:
      return {
        ...state,
        preferred_language: action.payload
      };
    case REQUEST_ACCOUNT_UPDATE:
      return {
        ...state,
        isAccountUpdateSuccess: false,
        hasErrorUpdateAccount: false,
        isLoadingUpdate: true
      };
    case ACCOUNT_UPDATE_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAccountUpdateSuccess: true,
        hasErrorUpdateAccount: false,
        isLoadingUpdate: false
      };
    case ACCOUNT_UPDATE_FAILED:
      return {
        ...state,
        hasErrorUpdateAccount: true,
        isAccountUpdateSuccess: false,
        isLoadingUpdate: false
      };
    case RESET_ACCOUNT_UPDATE:
      return {
        ...state,
        hasErrorUpdateAccount: false,
        isAccountUpdateSuccess: false,
        isLoadingUpdate: false
      };

    case USER_HIDE_ONBOARDING:
      return { ...state, ...action.payload };
    case SET_USER_BOOKED:
      return {
        ...state,
        already_booked: true
      };
    case SET_UG_WALKTHROUGH_SEEN:
      return {
        ...state,
        show_ug_walkthrough: false
      };
    case OVERWRITE_USER_DATA:
      return {
        ...state,
        ...action.payload
      };
    case USER_HIDE_CEREGO_POPUP:
      return { ...state, ...action.payload };
    case USER_HIDE_TUITION_BANNER:
      return { ...state, ...action.payload };
    case 'persist/REHYDRATE':
      return action?.payload?.user || {};

    case REQUEST_UPDATE_PASSWORD:
      return {
        ...state,
        isLoadingPassword: true,
        isPasswordChangeSuccess: false,
        hasErrorUpdatePassword: false
      };
    case PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoadingPassword: false,
        isPasswordChangeSuccess: true,
        hasErrorUpdatePassword: false
      };
    case PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        isLoadingPassword: false,
        isPasswordChangeSuccess: false,
        hasErrorUpdatePassword: true
      };
    case RESET_UPDATE_PASSWORD:
      return {
        ...state,
        isLoadingPassword: false,
        isPasswordChangeSuccess: false,
        hasErrorUpdatePassword: false
      };

    case UserActionTypes.CLEAR_USER:
      return initialState;
    case SET_AUTOMATIC_BOOKINGS:
      return {
        ...state,
        automatic_bookings: action.payload
      };
    case SET_AUTOMATIC_BOOKINGS_ASKED:
      return {
        ...state,
        automatic_bookings_asked: action.payload
      };
    default:
      return state;
  }
}
