import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import MyBookings from '../components/new-booking/MyBookings/MyBookings';
import Bookings from '../components/new-booking/Bookings/Bookings';
import AutomaticBookingsModal from '../components/automatic-bookings-modal';

import styles from '../style/containers/NewBooking.module.scss';
import { connect } from 'react-redux';
import { getEnrolledCourses } from '../actions/courses';
import getOrderInfo from '../actions/order-info';
import { updateAutomaticBookings } from '../actions/user';
import { sendSentryError } from '../setup/sentry';

const NewBooking = ({ getEnrolledCoursesAction, getOrderInfoAction, orderInfo, selectedCourse, token, user, updateAutomaticBookingsAction }) => {
  const [showAutomaticBookingsModal, setShowAutomaticBookingsModal] = useState(false);
  const isFeatureEnabled = useFeatureIsOn('automatic-bookings');

  useEffect(() => {
    if (!selectedCourse?.name) getEnrolledCoursesAction(token);
    if (!orderInfo?.all_orders) getOrderInfoAction(token);

    // Show the modal if the feature is enabled and the user hasn't made a choice yet
    if (isFeatureEnabled && user && !user.automatic_bookings_asked && !showAutomaticBookingsModal) {
      setShowAutomaticBookingsModal(true);
    }
  }, [selectedCourse, orderInfo, token, isFeatureEnabled, user]);

  const handleAcceptAutomaticBookings = async () => {
    try {
      await updateAutomaticBookingsAction(token, true);
      setShowAutomaticBookingsModal(false);
    } catch (error) {
      sendSentryError({ error, context: 'handleAcceptAutomaticBookings' });
      console.error('Error updating automatic bookings preference:', error);
    }
  };

  const handleDeclineAutomaticBookings = async () => {
    try {
      await updateAutomaticBookingsAction(token, false);
      setShowAutomaticBookingsModal(false);
    } catch (error) {
      sendSentryError({ error, context: 'handleDeclineAutomaticBookings' });
      console.error('Error updating automatic bookings preference:', error);
    }
  };

  return (
    <div>
      <div className={styles.newBookingWrapper}>
        <div className={styles.bookings}>
          <Bookings />
        </div>
        <div className={styles.myBookings}>
          <MyBookings />
        </div>
      </div>
      {isFeatureEnabled && (
        <AutomaticBookingsModal
          isOpen={showAutomaticBookingsModal}
          onClose={() => setShowAutomaticBookingsModal(false)}
          onAccept={handleAcceptAutomaticBookings}
          onDecline={handleDeclineAutomaticBookings}
        />
      )}
    </div>
  );
};

NewBooking.propTypes = {
  getEnrolledCoursesAction: PropTypes.func.isRequired,
  getOrderInfoAction: PropTypes.func.isRequired,
  orderInfo: PropTypes.object,
  selectedCourse: PropTypes.object,
  token: PropTypes.string,
  user: PropTypes.object,
  updateAutomaticBookingsAction: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  selectedCourse: state.courses.selectedCourseDetails.courseDetails,
  orderInfo: state.orderInfo,
  token: state.user?.token,
  user: state.user
});

export default connect(mapStateToProps, {
  getEnrolledCoursesAction: getEnrolledCourses,
  getOrderInfoAction: getOrderInfo,
  updateAutomaticBookingsAction: updateAutomaticBookings
})(NewBooking);
